// @flow

import './SoftGateBackground.scss';

import * as React from 'react';

import ResponsiveMedia from 'components/Shared/ResponsiveMedia/ResponsiveMedia';

const BACKGROUNDS = {
  cabin: {
    desktop: 'https://huckberry.imgix.net/softgate/sg-exp-0130/cabin4.jpg',
    mobile: 'https://huckberry.imgix.net/softgate/sg-exp-0130/cabin4.jpg'
  },
  journal: {
    desktop: 'https://huckberry.imgix.net/softgate/journal_softgate_bg.jpeg',
    mobile:
      'https://huckberry.imgix.net/softgate/journal_softgate_bg_mobile.jpg'
  },
  christmasTree: {
    mobile:
      'https://huckberry.imgix.net/softgate/ornament-soft-gate-mobile.jpg',
    desktop:
      'https://huckberry.imgix.net/softgate/ornament-soft-gate-desktop.jpg'
  },
  model: {
    mobile: 'https://huckberry.imgix.net/softgate/sitewide-bg-mobile-new.jpg',
    desktop: 'https://huckberry.imgix.net/softgate/sitewide-bg-desktop-new.jpg'
  },
  pappyDay: {
    desktop:
      'https://huckberry.imgix.net/softgate/sg-pappy-day-2024-desktop.jpeg',
    mobile: 'https://huckberry.imgix.net/softgate/sg-pappy-day-2024-mobile.jpeg'
  },
  truck: {
    desktop: 'https://huckberry.imgix.net/softgate/sg-exp-0130/truck1_exp.jpg',
    mobile: 'https://huckberry.imgix.net/softgate/sg-exp-0130/truck.jpg'
  }
};

type Props = {|
  background: $Keys<typeof BACKGROUNDS> | null
|};

const SoftGateBackground = ({ background }: Props) => {
  if (!background) {
    return null;
  }

  return (
    <>
      <div className="SoftGateBackground--desktop">
        <ResponsiveMedia
          sizes="(max-width: 1000px) 1px, (max-width: 1200px) 980px, 1150px"
          mediaProps={{
            className: 'SoftGateBackground'
          }}
          src={BACKGROUNDS[background].desktop}
          lazyLoad={false}
          alt="Gate Background Image"
        />
      </div>
      <div className="SoftGateBackground--mobile">
        <ResponsiveMedia
          sizes="(max-width: 1000px) 512px, 1px"
          mediaProps={{
            className: 'SoftGateBackground'
          }}
          src={BACKGROUNDS[background].mobile}
          lazyLoad={false}
          alt="Gate Background Image"
        />
      </div>
    </>
  );
};

export default SoftGateBackground;
