import { CartAddonsState, CartAddonsAction } from './types';

const initialState: CartAddonsState = {
  addonProducts: [],
  forYouProducts: []
};

const reducer = (
  state: CartAddonsState = initialState,
  action: CartAddonsAction
) => {
  switch (action.type) {
    case 'CART_ADDONS/GET_CART_ADDON_PRODUCTS_SUCCESS':
      return { ...state, addonProducts: action.addonProducts };
    case 'CART_ADDONS/GET_CART_ADDON_FOR_YOU_PRODUCTS_SUCCESS':
      return { ...state, forYouProducts: action.forYouProducts };
    default:
      return state;
  }
};

export default reducer;
