import { SagaIterator } from 'redux-saga';
import { call, takeEvery, put } from 'redux-saga/effects';

import {
  getCartAddonProductsSuccess,
  getCartAddonProductsFailure,
  getCartAddonForYouProductsSuccess,
  getCartAddonForYouProductsFailure
} from './action-creators';
import { fetchCartAddons } from './requests';

function* cartAddonsSaga(): SagaIterator {
  yield takeEvery('CART_ADDONS/GET_CART_ADDON_PRODUCTS', getCartAddonProducts);
  yield takeEvery(
    'CART_ADDONS/GET_CART_ADDON_FOR_YOU_PRODUCTS',
    getCartAddonForYouProducts
  );
}

function* getCartAddonProducts(): SagaIterator {
  try {
    const cartAddons = yield call(fetchCartAddons, 'default');
    yield put(getCartAddonProductsSuccess(cartAddons.data));
  } catch (err) {
    return getCartAddonProductsFailure('Error fetching cart addons');
  }
}

function* getCartAddonForYouProducts(): SagaIterator {
  try {
    const cartAddons = yield call(fetchCartAddons, 'forYou');
    yield put(getCartAddonForYouProductsSuccess(cartAddons.data));
  } catch (err) {
    return getCartAddonForYouProductsFailure(
      'Error fetching for you cart addons'
    );
  }
}

export default cartAddonsSaga;
