import { ProductTilePayloadType } from 'types/products';

const getCartAddonProducts = () => ({
  type: 'CART_ADDONS/GET_CART_ADDON_PRODUCTS'
});

const getCartAddonProductsSuccess = (
  addonProducts: ProductTilePayloadType[]
) => ({
  type: 'CART_ADDONS/GET_CART_ADDON_PRODUCTS_SUCCESS',
  addonProducts
});

const getCartAddonProductsFailure = (err: string) => ({
  type: 'CART_ADDONS/GET_CART_ADDON_PRODUCTS_FAILURE',
  error: err
});

const getCartAddonForYouProducts = () => ({
  type: 'CART_ADDONS/GET_CART_ADDON_FOR_YOU_PRODUCTS'
});

const getCartAddonForYouProductsSuccess = (
  forYouProducts: ProductTilePayloadType[]
) => ({
  type: 'CART_ADDONS/GET_CART_ADDON_FOR_YOU_PRODUCTS_SUCCESS',
  forYouProducts
});

const getCartAddonForYouProductsFailure = (err: string) => ({
  type: 'CART_ADDONS/GET_CART_ADDON_FOR_YOU_PRODUCTS_FAILURE',
  error: err
});

export {
  getCartAddonProducts,
  getCartAddonProductsSuccess,
  getCartAddonProductsFailure,
  getCartAddonForYouProducts,
  getCartAddonForYouProductsSuccess,
  getCartAddonForYouProductsFailure
};
